import React from 'react';
import styled from 'styled-components';
import background from './web_bg.webp'; // Import your background image
// import { FaTwitter, FaGithub, FaTelegram } from 'react-icons/fa'; // Import Font Awesome icons

import twitterLogo from './x2.svg';  // Import logos
// import githubLogo from './github2.svg';
import telegramLogo from './tg2.svg';


const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative; /* Required for overlay positioning */
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  overflow: hidden; /* Prevent scrolling */
  margin-top:-100px
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Dim the background */
  z-index: 1; /* Ensure overlay is above the background */
`;

const Content = styled.div`
  position: absolute; /* Change to absolute positioning */
  top: 65%;
  left: 10px; /* Align to the left of the screen */
  transform: translateY(-50%); /* Vertically center the content */
  padding: 20px;
  box-sizing: border-box;
  color: white;
  z-index: 2; /* Ensure content is above the overlay */
`;

const Title = styled.h1`
  font-family: 'Creepster'; /* Example font, adjust as needed */
  font-size: 48px;
  margin: 0;
`;

const Subtitle = styled.p`
font-family: 'Creepster'; /* Example font, adjust as needed */
  font-size: 25px;
  margin: 10px 0;
`;

const SocialLinks = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 15px;

  img {
    width: 40px;  /* Set the fixed width */
    height: 40px; /* Set the fixed height */
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.2); /* Add hover effect to enlarge the icons */
    }
  }
`;

export default function Home() {
  return (
    <Container>
      <Overlay />
      <Content>
        <Title>SwanSwap Protocol</Title>
        <Subtitle>
          Swap, earn, and deploy in one click on the leading decentralized <br></br>
          crypto trading protocol on Shape.
        </Subtitle>
        <SocialLinks>
          <a href="https://x.com/WorldSwap_" target="_blank" rel="noopener noreferrer">
            <img src={twitterLogo} alt="Twitter" />
          </a>
          {/* <a href="https://github.com" target="_blank" rel="noopener noreferrer">
            <img src={githubLogo} alt="GitHub" />
          </a> */}
          <a href="https://t.me/worldswap0" target="_blank" rel="noopener noreferrer">
            <img src={telegramLogo} alt="Telegram" />
          </a>
        </SocialLinks>
      </Content>
    </Container>
  );
}
